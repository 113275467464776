import logo from "./logo.webp"

const  clientData = {
   client_entity: 8,
   attorney_firm: 'formanlawoffices.com',
    attorney_name: 'Nick Mucerino',
    attorney_number: '1-800-827-7731',
    attorney_email: 'ted@formanlawoffices.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_explainer_-_forman_law+(720p).mp4',
    mainColor: '#1C2952',
    secondaryColor: '#f49231',
    siteLink: 'https://www.formanlawoffices.com',
    logo
}

export default clientData